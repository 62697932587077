html, body, #root {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

